import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`::: {.page title=“Page 1”}
::: {.layoutArea}
::: {.column}
`}<strong parentName="p">{`CROSSFIT OPEN WORKOUT 18.3`}</strong></p>
    <p>{`2 rounds for time of:`}</p>
    <p>{`100 double-unders`}</p>
    <p>{`20 overhead squats`}</p>
    <p>{`100 double-unders`}</p>
    <p>{`12 ring muscle-ups`}</p>
    <p>{`100 double-unders`}</p>
    <p>{`20 dumbbell snatches`}</p>
    <p>{`100 double-unders`}</p>
    <p>{`12 bar muscle-ups`}</p>
    <p>{`Time cap: 14 minutes`}</p>
    <p>{`VARIATIONS`}</p>
    <p><em parentName="p">{`Rx’d: (Ages 16-54)`}</em>{`\\
Men perform 115-lb. OHS, 50-lb. DB snatches Women perform 80-lb. OHS,
35-lb. DB snatches`}</p>
    <p><em parentName="p">{`Scaled: (Ages 16-54)`}</em>{`\\
Men perform single-unders, 45-lb. OHS, chin-over-bar pull-ups`}</p>
    <p>{`(for both MU sets), 35-lb. DB snatches`}</p>
    <p>{`Women perform single-unders, 35-lb. OHS, chin-over-bar pull-ups (for
both MU sets), 20-lb. DB snatches`}</p>
    <p><em parentName="p">{`Masters 55+:`}</em></p>
    <p>{`Men perform 75-lb. OHS, chest-to-bar pull-ups (for both MU sets), 35-lb.
DB snatches`}</p>
    <p>{`Women perform 55-lb. OHS, chest-to-bar pull-ups (for both MU sets),
20-lb. DB snatches`}</p>
    <p><em parentName="p">{`Scaled Masters 55+:`}</em>{`
:::
:::`}</p>
    <p>{`::: {.layoutArea}
::: {.column}
Men perform single-unders, 45-lb. OHS, jumping chest-to-bar pull-ups
(for both MU sets), 20-lb. DB snatches`}</p>
    <p>{`Women perform single-unders, 35-lb. OHS, jumping chest-to-bar pull-ups
(for both MU sets), 10-lb. DB snatches`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be judging Open WOD 18.3 today in class as much as possible
as well as on Saturday (rest day) during classes at 9:00 & 10:00am and
from 11:00-12:00.`}</em></strong>{`
:::
:::
:::`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      